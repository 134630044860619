import React from "react"

import IndexPage from "../components/IndexPage/IndexPage"

import "../styles/index.scss"

const Index = () => {
  return <IndexPage />
}

export default Index
