import React from "react"

import Nav from "../Nav"
import Head from "../Head"
import indexStyles from "./index.module.scss"

const Index = () => {
  const Year = new Date();

  return (
    <div className={indexStyles.container}>
    <Head title="Home"/>
      <div className={indexStyles.patternImage}></div>
      <div className={indexStyles.hero}>
        <Nav />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 699.573 346.14">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path
                  className={indexStyles.all}
                  d="M119.648,20.246a5.966,5.966,0,0,0-3.526,3.956q-.948,2.928-.946,9.46v85.312q0,6.538.946,9.46a5.96,5.96,0,0,0,3.526,3.956,29.343,29.343,0,0,0,8.428,1.376v3.44q-8.428-.514-26.144-.515-17.374,0-27,.515v-3.44a29.825,29.825,0,0,0,8.342-1.376,5.954,5.954,0,0,0,3.612-3.956q.943-2.922.946-9.46V77.694H42.248v41.28q0,6.538.946,9.46a5.962,5.962,0,0,0,3.526,3.956,29.35,29.35,0,0,0,8.428,1.376v3.44q-9.288-.514-25.972-.515-18.406,0-27.176.515v-3.44a29.825,29.825,0,0,0,8.342-1.376,5.954,5.954,0,0,0,3.612-3.956q.944-2.922.946-9.46V33.662a33.825,33.825,0,0,0-.946-9.46,5.961,5.961,0,0,0-3.612-3.956A29.937,29.937,0,0,0,2,18.87V15.43q8.772.516,27.176.517,16.682,0,25.972-.517v3.44a29.457,29.457,0,0,0-8.428,1.376A5.969,5.969,0,0,0,43.194,24.2q-.947,2.928-.946,9.46V74.254h45.58V33.662a33.825,33.825,0,0,0-.946-9.46,5.961,5.961,0,0,0-3.612-3.956,29.937,29.937,0,0,0-8.342-1.376V15.43q9.63.516,27,.517,17.713,0,26.144-.517v3.44A29.45,29.45,0,0,0,119.648,20.246Z"
                  style={{
                    fill: "none",
                    stroke: "#e45865",
                    strokeMiterlimit: 10,
                    strokeWidth: "4px",
                  }}
                />
                <path
                  className={`${indexStyles.all} ${indexStyles.path2}`}
                  d="M207.882,111.75l3.1,1.032a41.027,41.027,0,0,1-12.3,19.006q-8.862,7.828-22.1,7.827-18.234,0-28.983-11.611T136.846,93.69q0-23.22,11.525-35.518t30.615-12.3q32.336,0,32.336,38.184H162.99q-.173,2.58-.172,8.084,0,11.352,3.182,18.921t8.256,11.008a19.077,19.077,0,0,0,10.922,3.439Q199.28,125.51,207.882,111.75ZM167.72,57.14q-4.042,7.656-5.074,23.65h27q.343-13.584-2.666-22.445t-9.029-8.859Q171.763,49.486,167.72,57.14Z"
                  style={{
                    fill: "none",
                    stroke: "#e45865",
                    strokeMiterlimit: 10,
                    strokeWidth: "4px",
                  }}
                />
                <path
                  d="M256.386,121.21q0,7.055,2.408,9.718t8.6,2.666v3.612q-17.89-.685-23.048-.688-5.85,0-23.736.688v-3.612q6.019,0,8.514-2.666t2.494-9.718V23.17q0-7.91-2.494-11.524T220.61,8.034V4.422q5.5.516,10.664.516,14.964,0,25.112-2.407Z"
                  style={{
                    fill: "none",
                    stroke: "#e45865",
                    strokeMiterlimit: 10,
                    strokeWidth: "4px",
                  }}
                  className={`${indexStyles.all} ${indexStyles.path3}`}
                />
                <path
                  d="M310.907,121.21q0,7.055,2.408,9.718t8.6,2.666v3.612q-17.892-.685-23.049-.688-5.849,0-23.736.688v-3.612q6.02,0,8.515-2.666t2.494-9.718V23.17q0-7.91-2.494-11.524t-8.515-3.612V4.422q5.5.516,10.664.516,14.964,0,25.112-2.407Z"
                  style={{
                    fill: "none",
                    stroke: "#e45865",
                    strokeMiterlimit: 10,
                    strokeWidth: "4px",
                  }}
                  className={`${indexStyles.all} ${indexStyles.path4}`}
                />
                <path
                  d="M402.926,57.14q11.18,11.268,11.18,35.69t-11.18,35.6q-11.182,11.184-30.96,11.181-19.607,0-30.874-11.181t-11.266-35.6q0-24.42,11.266-35.69t30.874-11.266Q391.744,45.874,402.926,57.14Zm-42.741,2.924Q355.8,70.816,355.8,92.83t4.387,32.68q4.385,10.667,11.781,10.664,7.567,0,11.868-10.578t4.3-32.766q0-22.188-4.385-32.852T371.966,49.314Q364.57,49.314,360.185,60.064Z"
                  style={{
                    fill: "none",
                    stroke: "#e45865",
                    strokeMiterlimit: 10,
                    strokeWidth: "4px",
                  }}
                  className={`${indexStyles.all} ${indexStyles.path5}`}
                />
                <path
                  d="M454.524,116.653q3.956,3.526,3.956,9.717t-3.956,9.718q-3.959,3.524-10.664,3.527T433.2,136.088q-3.959-3.522-3.956-9.718t3.956-9.717q3.956-3.525,10.664-3.527T454.524,116.653Z"
                  style={{
                    fill: "#e45865",
                    stroke: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: "0.25px",
                  }}
                  className={indexStyles.path6}
                />
                <g className={indexStyles.heroGreet}>
                  <path
                    d="M55.148,225.271a29.421,29.421,0,0,0-8.428,1.376,5.967,5.967,0,0,0-3.526,3.956q-.947,2.926-.946,9.46v85.311q0,6.54.946,9.46a5.96,5.96,0,0,0,3.526,3.956,29.279,29.279,0,0,0,8.428,1.376v3.441q-9.288-.516-25.972-.516-18.406,0-27.176.516v-3.441a29.751,29.751,0,0,0,8.342-1.376,5.952,5.952,0,0,0,3.612-3.956q.944-2.922.946-9.46V240.063a33.815,33.815,0,0,0-.946-9.46,5.958,5.958,0,0,0-3.612-3.956A29.9,29.9,0,0,0,2,225.271V221.83q8.772.518,27.176.517,16.682,0,25.972-.517Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                  <path
                    d="M111.3,339.135q-5.418-5.676-5.418-14.964,0-7.74,4.042-12.814a27.6,27.6,0,0,1,9.8-7.826,144.994,144.994,0,0,1,14.706-5.848,76.341,76.341,0,0,0,13.846-5.935,8.192,8.192,0,0,0,4.386-7.309V271.023q0-7.226-3.354-11.266t-10.406-4.042q-8.256,0-12.556,4.128a16,16,0,0,1,7.482,5.16,12.825,12.825,0,0,1,2.666,8.084,11.676,11.676,0,0,1-3.956,9.287,14.161,14.161,0,0,1-9.632,3.441q-6.023,0-9.288-3.784a15.42,15.42,0,0,1-.946-17.63,23.032,23.032,0,0,1,7.138-6.279,39.766,39.766,0,0,1,11.438-4.3,66.9,66.9,0,0,1,14.878-1.548,55.127,55.127,0,0,1,14.62,1.719,22.991,22.991,0,0,1,10.32,5.849,18.975,18.975,0,0,1,5.074,9.288,68.513,68.513,0,0,1,1.29,14.964v46.784a15.6,15.6,0,0,0,.774,5.848,2.977,2.977,0,0,0,3.01,1.72q2.58,0,6.192-2.924l1.72,2.924q-7.74,6.366-18.92,6.364-9.462,0-13.244-3.784t-3.956-10.148q-8.6,13.93-25.284,13.932Q116.72,344.811,111.3,339.135Zm41.366-12.385V291.491q-2.064,3.441-8.772,7.568a41.833,41.833,0,0,0-10.148,8.6q-3.442,4.3-3.44,12.384,0,7.055,2.924,10.407a9.762,9.762,0,0,0,7.74,3.354Q147.68,333.8,152.668,326.75Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                  <path
                    d="M334.9,337.328q2.491,2.67,8.514,2.666v3.613q-17.2-.686-22.7-.689-4.816,0-21.328.689v-3.613q4.644,0,6.45-2.58t1.806-9.8V274.118q0-7.223-2.15-10.75t-8.17-3.525a14.414,14.414,0,0,0-11.094,5.332,22.974,22.974,0,0,0-5.59,13.071v49.365q0,7.055,2.15,9.717t7.826,2.666v3.613q-16.512-.686-21.672-.689-4.817,0-21.328.689v-3.613q4.644,0,6.45-2.666t1.806-9.717V274.118q0-7.223-1.892-10.75t-7.4-3.525a14.973,14.973,0,0,0-11.782,5.762,21.151,21.151,0,0,0-4.9,14.19v47.816q0,7.055,1.806,9.717t6.45,2.666v3.613q-15.137-.686-20.3-.689t-23.736.689v-3.613q6.192,0,8.6-2.666t2.408-9.717v-54.18q0-7.911-2.408-11.524t-8.6-3.612v-3.612q5.5.516,10.664.516,15.307,0,25.112-2.409v15.481q7.566-16,26.66-16,13.07,0,18.92,6.708,3.266,3.443,4.3,10.32,4.471-9.288,11.7-13.158a35.165,35.165,0,0,1,16.856-3.87q13.069,0,18.92,6.708a18.67,18.67,0,0,1,3.956,8.427,65.512,65.512,0,0,1,1.2,13.933v46.268Q332.406,334.666,334.9,337.328Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                  <path
                    d="M471.552,222.347a59.657,59.657,0,0,1,14.1,8.256,5.564,5.564,0,0,0,3.268,1.548q3.438,0,4.472-10.321h3.956q-.69,11.7-.687,41.624h-3.957q-1.548-12.726-4.127-19.435a27.744,27.744,0,0,0-8.773-11.7,29.2,29.2,0,0,0-9.975-6.278,33.262,33.262,0,0,0-11.869-2.15q-17.375,0-25.8,16.942t-8.428,42.226q0,25.115,8.772,41.968t26.144,16.856a32.968,32.968,0,0,0,11.61-2.15,25.672,25.672,0,0,0,9.718-6.279,25.15,25.15,0,0,0,8.858-12.04q2.492-7.222,3.87-21.155h3.957q0,31.132.687,43.344H493.4a30.529,30.529,0,0,0-1.462-7.826q-.948-2.322-2.666-2.323a7.543,7.543,0,0,0-3.612,1.376,72.6,72.6,0,0,1-14.792,8.343,45.63,45.63,0,0,1-17.028,2.838q-18.233,0-31.906-7.4A51.636,51.636,0,0,1,400.688,317.2q-7.57-14.015-7.568-33.454,0-18.918,7.74-33.54a56.087,56.087,0,0,1,21.586-22.7q13.843-8.084,31.562-8.084A46.825,46.825,0,0,1,471.552,222.347Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                  <path
                    d="M517.388,339.135q-5.418-5.676-5.418-14.964,0-7.74,4.042-12.814a27.6,27.6,0,0,1,9.8-7.826,144.994,144.994,0,0,1,14.706-5.848,76.341,76.341,0,0,0,13.846-5.935,8.192,8.192,0,0,0,4.386-7.309V271.023q0-7.226-3.354-11.266t-10.406-4.042q-8.256,0-12.556,4.128A16,16,0,0,1,539.92,265a12.819,12.819,0,0,1,2.665,8.084,11.678,11.678,0,0,1-3.955,9.287A14.161,14.161,0,0,1,529,285.815q-6.023,0-9.289-3.784a15.422,15.422,0,0,1-.945-17.63,23.032,23.032,0,0,1,7.138-6.279,39.766,39.766,0,0,1,11.438-4.3,66.907,66.907,0,0,1,14.878-1.548,55.137,55.137,0,0,1,14.62,1.719,22.991,22.991,0,0,1,10.32,5.849,18.975,18.975,0,0,1,5.074,9.288,68.513,68.513,0,0,1,1.29,14.964v46.784a15.6,15.6,0,0,0,.774,5.848,2.976,2.976,0,0,0,3.01,1.72q2.58,0,6.192-2.924l1.72,2.924q-7.74,6.366-18.92,6.364-9.461,0-13.244-3.784a14.276,14.276,0,0,1-3.956-10.148q-8.6,13.93-25.284,13.932Q522.8,344.811,517.388,339.135Zm41.366-12.385V291.491q-2.064,3.441-8.772,7.568a41.84,41.84,0,0,0-10.149,8.6q-3.441,4.3-3.439,12.384,0,7.055,2.924,10.407a9.76,9.76,0,0,0,7.74,3.354Q553.766,333.8,558.754,326.75Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                  <path
                    d="M683.108,258.983a18.809,18.809,0,0,1,4.128,8.427,64.028,64.028,0,0,1,1.2,13.933v46.268q0,7.055,2.494,9.717t8.514,2.666v3.613q-17.2-.686-22.7-.689-5.162,0-22.361.689v-3.613q5.161,0,7.225-2.666t2.063-9.717V274.118q0-7.05-2.321-10.664t-8.343-3.611a15.383,15.383,0,0,0-12.04,5.762,20.917,20.917,0,0,0-4.987,14.19v47.816q0,7.055,2.063,9.717t7.225,2.666v3.613q-15.828-.686-21.328-.689-5.16,0-23.736.689v-3.613q6.192,0,8.6-2.666t2.408-9.717v-54.18q0-7.911-2.408-11.524t-8.6-3.612v-3.612q5.5.516,10.664.516,15.306,0,25.112-2.409v15.481q7.74-16,28.208-16Q677.257,252.275,683.108,258.983Z"
                    style={{
                      fill: "#1c528a",
                      stroke: "#1c528a",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.25px",
                    }}
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        
        <div className={indexStyles.footer}>© {Year.getFullYear()}</div>
      </div>
    </div>
  )
}

export default Index
